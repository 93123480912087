import postJSON from "../../posts.json";
import Feelings from "../../components/feelings/feelings";
import countWordMatches from "../../components/feelings/countWordMatches.jsx";

const firstPostContent = postJSON[0].content;

// Count matching feelings in the first posts content
let countFeelings = countWordMatches(Feelings, firstPostContent);

// Set number of start branches
const feelings = ["Angst", "Ekel", "Freude", "Kummer", "Wut"];
let startBranches = 0;
for (let i = 0; i < feelings.length; i++) {
  if (countFeelings[feelings[i]] > 0) {
    startBranches += 1;
  }
}

const defaultValues = {
  sketch: true,
  startBranches: startBranches,
  angstFrequence: countFeelings.Angst,
  ekelFrequence: countFeelings.Ekel,
  freudeFrequence: countFeelings.Freude,
  kummerFrequence: countFeelings.Kummer,
  wutFrequence: countFeelings.Wut,
  isStopped: false,
  isStarted: false,
};

export default defaultValues;
