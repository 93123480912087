import React from "react";
import { Link } from "react-router-dom";

const ButtonDirection = ({ link, direction, label, condition, onClick }) => {
  const classNames = `link-direction btn-direction ${
    condition ? "" : "notAllowed"
  }`;
  const textClassNames = `btn-direction__text ${
    condition ? "" : "lineThrough"
  }`;
  const lineRightClassNames = `btn-direction__line btn-direction__line__right__${direction} ${
    condition ? "" : "lineThrough"
  }`;
  const lineThroughClassNames = `btn-direction__line btn-direction__line__lineThrough__${direction} ${
    condition ? "" : "lineThrough"
  }`;
  const lineLeftClassNames = `btn-direction__line btn-direction__line__left__${direction} ${
    condition ? "" : "lineThrough"
  }`;

  return (
    <Link to={link} className={classNames} onClick={onClick}>
      <span className={lineRightClassNames} />
      <span className={textClassNames}>
        <span className={lineThroughClassNames} />
        {label}
      </span>
      <span className={lineLeftClassNames} />
    </Link>
  );
};

export default ButtonDirection;
