import React, { useState, useContext, useEffect, createContext } from "react";
import postJSON from "../../posts.json";
import { Link, useParams } from "react-router-dom";
import Feelings from "../feelings/feelings.jsx";
import countWordMatches from "../feelings/countWordMatches.jsx";
import markFeelings from "../feelings/markFeelings";
import branches from "../../p5-sketches/branches";
import p5Wrapper from "../p5-wrapper/p5wrapper";
import { handleFormat } from "../utility/handleFormat";
import {
  AppDispatchContext,
  AppStateContext,
} from "../../App/appStateProvider/appStateProvider";
import { generate } from "shortid";
import Counter from "../counter/counter";
import ButtonDirection from "../btn-direction/btn-direction";
import BloodCount from "../counts/bloodcount";

const P5Wrapper = p5Wrapper(generate());

export const postContext = createContext();

const Post = (props) => {
  const { page, setPage, logo, postMortality, setPostMortality } = props;

  const [postState, setPostState] = useState({
    index: 0,
    post: {},
    previousPost: {},
    nextPost: {},
    visibility: true,
    drill: false,
    rotation: "back",
    delay: 0,
  });

  const { id } = useParams();
  const index = postJSON.findIndex((post) => post.slug === id);
  const post = postJSON[index];
  const previousPost = postJSON[index - 1];
  const nextPost = postJSON[index + 1];

  // -------------------------------------------------------------
  // Variables
  // -------------------------------------------------------------
  let [visibility, setVisibility] = useState(true);
  let [drill, setDrill] = useState(false);
  let [rotation, setRotation] = useState("back");
  let [delay, setDelay] = useState(100);
  let [mainBranches, setMainBranches] = useState(0);
  let [countFeelings, setCountFeelings] = useState(0);

  // -------------------------------------------------------------
  // Get posts list data function
  // -------------------------------------------------------------
  const PostDataTime = ({ id }) => {
    return (
      <Link
        to={index === id ? "#" : `/posts/${postJSON[id].slug}`}
        className={"btn-postdatatime"}
        onClick={() => {
          changePost(postMortality, "id", id);
        }}
      >
        <span>{handleFormat(postJSON[id].year)}</span>
        <span>{handleFormat(postJSON[id].month)}</span>
        <span>{handleFormat(postJSON[id].day)}</span>
      </Link>
    );
  };

  // -------------------------------------------------------------
  // Change text function
  // -------------------------------------------------------------
  const animateCircle = () => {
    setVisibility(false);
    setDrill(true);

    const timer = setTimeout(() => {
      setVisibility(true);
      setDrill(false);
    }, 3000);
    return () => clearTimeout(timer);
  };

  // -------------------------------------------------------------
  // Change theme function
  // -------------------------------------------------------------
  const changeTheme = (mortality) => {
    if (mortality === "true") {
      document.documentElement.setAttribute("data-theme", "light");
      localStorage.setItem("theme", "light");
    } else if (mortality === "false") {
      document.documentElement.setAttribute("data-theme", "dark");
      localStorage.setItem("theme", "dark");
    }
  };
  changeTheme(postMortality);

  useEffect(() => {
    changePost(postMortality, "back", 0);
    animateCircle();
  }, [page]);

  // Call it after every rendering
  useEffect(() => {
    setPostMortality(postJSON[index].mortality);
    changeTheme(postMortality);
    setPage("post");

    setCountFeelings(
      (countFeelings = countWordMatches(Feelings, post.content))
    );

    post.content = markFeelings(countFeelings.matchedWords, post.content);

    // Set number of start branches
    setMainBranches((mainBranches = 0));
    if (countFeelings.Angst > 0) {
      mainBranches += 1;
    }
    if (countFeelings.Ekel > 0) {
      mainBranches += 1;
    }
    if (countFeelings.Freude > 0) {
      mainBranches += 1;
    }
    if (countFeelings.Kummer > 0) {
      mainBranches += 1;
    }
    if (countFeelings.Wut > 0) {
      mainBranches += 1;
    }

    reloadSketch();

    setPostState({
      ...postState,
      index: index,
      post: post,
      previousPost: previousPost,
      nextPost: nextPost,
      visibility: visibility,
      drill: drill,
      rotation: rotation,
      delay: delay,
    });
  }, [index]);

  // -------------------------------------------------------------
  // Change posts function
  // -------------------------------------------------------------
  const changePost = (mortality, direction, postId) => {
    // Change text and rotate circle
    if (direction === "back" && index > 0) {
      animateCircle();
      setRotation((rotation = "back"));
    } else if (direction === "forward" && index < postJSON.length - 1) {
      animateCircle();
      setRotation((rotation = "forward"));
    } else if (direction === "id") {
      animateCircle();
      if (postId > index) {
        setRotation((rotation = "forward"));
      } else {
        setRotation((rotation = "back"));
      }
    }

    setDelay((delay = 3000));
  };

  // -------------------------------------------------------------
  // Set p5 values
  // -------------------------------------------------------------
  const dispatch = useContext(AppDispatchContext);
  const {
    startBranches,
    angstFrequence,
    ekelFrequence,
    freudeFrequence,
    kummerFrequence,
    wutFrequence,
    sketch,
    isStopped,
    isStarted,
  } = useContext(AppStateContext);

  const reloadSketch = () => {
    dispatch({
      type: "STOP",
      payload: true,
    });
    dispatch({
      type: "START",
      payload: true,
    });
    dispatch({
      type: "SET_START_BRANCHES",
      payload: mainBranches,
    });
    dispatch({
      type: "SET_BRANCH_VALUE_ANGST",
      payload: countFeelings.Angst,
    });
    dispatch({
      type: "SET_BRANCH_VALUE_EKEL",
      payload: countFeelings.Ekel,
    });
    dispatch({
      type: "SET_BRANCH_VALUE_FREUDE",
      payload: countFeelings.Freude,
    });
    dispatch({
      type: "SET_BRANCH_VALUE_KUMMER",
      payload: countFeelings.Kummer,
    });
    dispatch({
      type: "SET_BRANCH_VALUE_WUT",
      payload: countFeelings.Wut,
    });

    const timer = setTimeout(() => {
      dispatch({
        type: "STOP",
        payload: false,
      });
    }, delay);
    return () => clearTimeout(timer);
  };

  // Reset states on browser back
  useEffect(() => {
    return () => {
      setPostMortality(postJSON[index].mortality);
      setPage("home");
    };
  }, []);

  // -------------------------------------------------------------
  // Rendering
  // -------------------------------------------------------------
  return (
    <postContext.Provider value={[postState, setPostState]}>
      <div className={"wrapper wrapper__post"}>
        <Link
          to={"/"}
          className={`logo logo__link ${drill ? "drill" : ""} ${
            page === "home" ? "logo-position" : "logo-position--top"
          } ${rotation === "forward" ? "rotateForward" : "rotateBack"}`}
        >
          {logo}
        </Link>

        {/* p5 sketch */}
        {sketch && (
          <P5Wrapper
            sketch={branches}
            dispatch={dispatch}
            state={{
              startBranches,
              angstFrequence,
              ekelFrequence,
              freudeFrequence,
              kummerFrequence,
              wutFrequence,
              isStopped,
              isStarted,
            }}
          />
        )}

        {/* Content wrapper */}
        <div className={"wrapper"}>
          {/* Navigation */}
          <div className={"navigation navigation-post"}>
            {/* Zurück */}
            <ButtonDirection
              link={previousPost ? `/posts/${previousPost.slug}` : "#"}
              direction={"back"}
              label={"Züruck"}
              condition={index > 0}
              onClick={() => {
                changePost(postMortality, "back");
              }}
            />

            {/* Counter */}
            <Counter
              postYear={post.year}
              postMonth={post.month}
              postDay={post.day}
            />

            {/* Weiter */}
            <ButtonDirection
              link={nextPost ? `/posts/${nextPost.slug}` : "#"}
              direction={"forward"}
              label={"Weiter"}
              condition={nextPost}
              onClick={() => {
                changePost(postMortality, "forward");
              }}
            />
          </div>

          {/* Quicklinks */}
          <div className={"postdatatime postdatatime-Post"}>
            <div className={"postdatatime__back"}>
              <PostDataTime id={1} />
              <PostDataTime id={2} />
              <PostDataTime id={3} />
            </div>
            <span>•</span>
            <div className={"postdatatime__forward"}>
              <PostDataTime id={3} />
              <PostDataTime id={2} />
              <PostDataTime id={1} />
            </div>
          </div>

          <BloodCount
            counts={{
              angst: angstFrequence,
              ekel: ekelFrequence,
              freude: freudeFrequence,
              kummer: kummerFrequence,
              wut: wutFrequence,
            }}
          />

          {/* Posts content */}
          <div
            className={`postContent ${visibility === true ? "visible" : ""}`}
          >
            <div
              className={`${postMortality === "true" ? "mortalityTrue " : ""} ${
                postMortality === "false" ? "mortalityFalse " : ""
              }`}
            >
              {/* Title */}
              <h2>{post.title}</h2>

              {/* Text */}
              <p dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </div>
        </div>
      </div>
    </postContext.Provider>
  );
};

export default Post;
