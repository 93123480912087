import React from "react";

const WeatherCount = ({ counts }) => {
  return (
    <section className={"section-weather-count"}>
      <aside className={"weather-count"}>
        <section className={"weather-count__counts"}>
          {Object.values(counts).map((value, index) => (
            <span key={index}>{value}</span>
          ))}
        </section>
        <section className={"weather-count__desc"}>
          {Object.keys(counts).map((key, index) => (
            <span key={index}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </span>
          ))}
        </section>
      </aside>
    </section>
  );
};

export default WeatherCount;
