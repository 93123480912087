import angstJSON from "../../json/angst.json";
import ekelJSON from "../../json/ekel.json";
import freudeJSON from "../../json/freude.json";
import kummerJSON from "../../json/kummer.json";
import wutJSON from "../../json/wut.json";

const extractValuesFromJSON = (json) => {
  const values = [];

  // Loop through the nouns, adjectives, and verbs arrays
  json.forEach((category) => {
    Object.values(category).forEach((words) => {
      words.forEach((word) => {
        // Add the word and its properties to the values array
        values.push(word.word);
        if (word.plural) {
          values.push(word.plural);
        }
        if (word.comparative) {
          values.push(word.comparative);
        }
        if (word.superlative) {
          values.push(word.superlative);
        }
        if (word.present1sg) {
          values.push(word.present1sg);
        }
        if (word.present2sg) {
          values.push(word.present2sg);
        }
        if (word.present3sg) {
          values.push(word.present3sg);
        }
        if (word.present1pl) {
          values.push(word.present1pl);
        }
        if (word.present2pl) {
          values.push(word.present2pl);
        }
        if (word.present3pl) {
          values.push(word.present3pl);
        }
        if (word.pretpfsg) {
          values.push(word.pretpfsg);
        }
        if (word.pretpfpl) {
          values.push(word.pretpfpl);
        }
        if (word.part) {
          values.push(word.part);
        }
      });
    });
  });

  return values;
};

const angstArray = extractValuesFromJSON(angstJSON);
const ekelArray = extractValuesFromJSON(ekelJSON);
const freudeArray = extractValuesFromJSON(freudeJSON);
const kummerArray = extractValuesFromJSON(kummerJSON);
const wutArray = extractValuesFromJSON(wutJSON);

function removeDuplicates(array) {
  return Array.from(new Set(array));
}

const FeelingsRaw = {
  angstArray,
  ekelArray,
  freudeArray,
  kummerArray,
  wutArray,
};

const Feelings = {
  angstArray: removeDuplicates(FeelingsRaw.angstArray),
  ekelArray: removeDuplicates(FeelingsRaw.ekelArray),
  freudeArray: removeDuplicates(FeelingsRaw.freudeArray),
  kummerArray: removeDuplicates(FeelingsRaw.kummerArray),
  wutArray: removeDuplicates(FeelingsRaw.wutArray),
};

export default Feelings;
