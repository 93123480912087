import React, { useContext, useState, useEffect, useCallback } from "react";
import postJSON from "../../posts.json";
import { ApiContext } from "../../App/appStateProvider/appStateProvider";
import ButtonDirection from "../btn-direction/btn-direction";
import WeatherCount from "../counts/weathercount";

const Home = ({
  logo,
  handleButtonClick,
  clouds,
  setCloudCount,
  setPostMortality,
}) => {
  const [globalState] = useContext(ApiContext);
  const [initRotation, setInitRotation] = useState(true);

  const sendCloudCount = useCallback(() => {
    const percentage = globalState.cloudCover / 100;
    setCloudCount(percentage * 20);
  }, [globalState.cloudCover, setCloudCount]);

  const setBackground = useCallback(() => {
    var now = new Date();
    if (now.getHours() >= 8 && now.getHours() < 20) {
      setPostMortality(true);
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      setPostMortality(false);
      document.documentElement.setAttribute("data-theme", "dark");
    }
  }, [setPostMortality]);

  useEffect(() => {
    sendCloudCount();
    setBackground();
  }, [
    globalState.timeIndex,
    globalState.cloudCover,
    globalState.uv_index,
    sendCloudCount,
    setBackground,
  ]);

  return (
    <div className={"wrapper wrapper__home"}>
      {clouds}

      <WeatherCount
        counts={{
          Uhr: globalState.timeIndex,
          Wolken: globalState.cloudCover,
          UV: globalState.uv_index,
        }}
      />

      <div className={"navigation navigation-home"}>
        <ButtonDirection
          link={`/posts/${postJSON[0].slug}`}
          direction={"back"}
          label={"Zum Start"}
          condition={true}
          onClick={() => {
            handleButtonClick();
            setInitRotation(false);
          }}
        />

        <div className={"placeholder-logo"}></div>
        <section className={`logo ${initRotation ? "rotateBack" : ""}`}>
          {logo}
        </section>

        <ButtonDirection
          link={`/posts/${postJSON[0].slug}`}
          direction={"forward"}
          label={"Zum Ende"}
          condition={true}
          onClick={() => {
            handleButtonClick();
            setInitRotation(false);
          }}
        />
      </div>
    </div>
  );
};

export default Home;
