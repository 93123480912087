import React, {
  createContext,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";

import initState from "./init-state";
import reducer from "./reducer";

export const AppDispatchContext = createContext(() => {});
export const AppStateContext = createContext(initState);
export const ApiContext = createContext();

const AppStateProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    timeIndex: 0,
    cloudCover: 0,
    uv_index: 0,
  });

  let currentTime = useRef(null);
  const [state, dispatch] = useReducer(reducer, initState);
  const [cloudCover, setCloudCover] = useState([]);
  const [uv_index, setUV_index] = useState([]);
  const [timeIndex, setTimeIndex] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(0);

  useEffect(() => {
    const loadData = () => {
      currentTime.current = new Date();
      setTimeIndex(currentTime.current.getHours());

      fetch(
        "https://api.open-meteo.com/v1/forecast?latitude=52.52&longitude=13.41&hourly=cloudcover,uv_index" +
          "&date=" +
          currentTime.current,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setCloudCover(data.hourly.cloudcover);
          setUV_index(data.hourly.uv_index);
          setDataLoaded(currentTime.current);
        })
        .catch((error) => console.error(error));
    };

    loadData();
    const intervalId = setInterval(loadData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      cloudCover: cloudCover[timeIndex],
      uv_index: uv_index[timeIndex],
      timeIndex: timeIndex,
    }));
  }, [dataLoaded, cloudCover, timeIndex, uv_index]);

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>
        <ApiContext.Provider value={[globalState, setGlobalState]}>
          {children}
        </ApiContext.Provider>
      </AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

export default AppStateProvider;
