import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../App/appStateProvider/appStateProvider";

function Circle(props) {
  const [globalState] = useContext(ApiContext);
  const { fontVariation, setFontVariation } = props;

  useEffect(() => {
    const setFontVariationSettings = () => {
      var percentage = 1 - globalState.uv_index / 7;
      var value = percentage * 200 + 100;
      setFontVariation(value);
    };

    setFontVariationSettings();
    document.documentElement.style.setProperty("--luke-fill", fontVariation);
  }, [globalState, fontVariation, setFontVariation]);

  return (
    <svg
      className={"circle " + props.drill + " " + props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
    >
      <defs>
        <path
          d="M300,150A150,150,0,1,1,150,0,150,150,0,0,1,300,150Z"
          data-inverse={"M300,150A150,150,0,1,0,150,300,150,150,0,0,0,300,150Z"}
          id="textcircle"
        />
      </defs>
      <text dy={"50"}>
        <textPath textLength="925" href="#textcircle">
          immerwæhrend•
        </textPath>
      </text>
    </svg>
  );
}

export default Circle;
