import React, { useCallback } from "react";
import CountUp from "react-countup";
import { handleFormat } from "../utility/handleFormat";

const Counter = ({ postYear, postMonth, postDay, animation }) => {
  const changeFormat = useCallback(
    (value) => handleFormat(value).toLocaleString(),
    []
  );

  return (
    <h1 className={"counter"}>
      <span>
        <CountUp
          end={postYear}
          start={animation === false ? postYear : "31"}
          duration={3}
          preserveValue={true}
          formattingFn={changeFormat}
        />
      </span>
      <span>
        <CountUp
          end={postMonth}
          start={animation === false ? postMonth : "07"}
          duration={3}
          preserveValue={true}
          formattingFn={changeFormat}
        />
      </span>
      <span>
        <CountUp
          end={postDay}
          start={animation === false ? postDay : "17"}
          duration={3}
          preserveValue={true}
          formattingFn={changeFormat}
        />
      </span>
    </h1>
  );
};

export default Counter;
