const markFeelings = (words, content) => {
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const regex = new RegExp(
      `(^|\\s|«|\\()(${word})(\\s|,|\\.|;|:|»|!|\\?|^|\\))`,
      "gi"
    );
    content = content.replace(
      regex,
      (match, p1, p2, p3) =>
        `${p1}<span class="feeling_wrapper"><span class='feeling'><span class='feeling-letter-start'>${
          p2[0]
        }</span>${p2
          .slice(1, -2)
          .split("")
          .map(
            (letter, index) => `<span class='feeling-letter'>${letter}</span>`
          )
          .join("")}<span class='feeling-letter-end'>${
          p2[p2.length - 2]
        }</span><span class='feeling-letter'>${
          p2[p2.length - 1]
        }</span></span><span class="feeling--hidden">${p2}</span></span>${p3}`
    );
  }

  return content;
};

export default markFeelings;
