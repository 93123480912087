const Reducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_START_BRANCHES":
      return {
        ...state,
        startBranches: payload,
      };

    case "SET_BRANCH_VALUE_ANGST":
      return {
        ...state,
        angstFrequence: payload,
      };

    case "SET_BRANCH_VALUE_EKEL":
      return {
        ...state,
        ekelFrequence: payload,
      };

    case "SET_BRANCH_VALUE_FREUDE":
      return {
        ...state,
        freudeFrequence: payload,
      };

    case "SET_BRANCH_VALUE_KUMMER":
      return {
        ...state,
        kummerFrequence: payload,
      };

    case "SET_BRANCH_VALUE_WUT":
      return {
        ...state,
        wutFrequence: payload,
      };

    case "STOP":
      return {
        ...state,
        isStopped: payload,
      };

    case "START":
      return {
        ...state,
        isStarted: payload,
      };

    default:
      return state;
  }
};
export default Reducer;
