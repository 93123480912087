import React, { useRef, useEffect } from "react";
import cloudImage from "../../assets/cloud.png";

const Clouds = ({ cloudCount }) => {
  const viewport = useRef(null);
  const world = useRef({});

  (function () {
    var lastTime = 0;
    var vendors = ["ms", "moz", "webkit", "o"];
    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame =
        window[vendors[x] + "RequestAnimationFrame"];
      window.cancelRequestAnimationFrame =
        window[vendors[x] + "CancelRequestAnimationFrame"];
    }

    if (!window.requestAnimationFrame)
      window.requestAnimationFrame = function (callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function () {
          callback(currTime + timeToCall);
        }, timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      };

    if (!window.cancelAnimationFrame)
      window.cancelAnimationFrame = function (id) {
        clearTimeout(id);
      };
  })();

  let layers = [],
    d = 0,
    worldXAngle = 0,
    worldYAngle = 0,
    count = cloudCount / 2,
    counts = cloudCount;

  const objects = useRef([]);

  useEffect(() => {
    const generate = () => {
      objects.current = [];
      const myElement = world.current;
      while (myElement.firstChild) {
        myElement.removeChild(myElement.firstChild);
      }
      for (var j = 0; j < counts; j++) {
        objects.current.push(createCloud());
      }
    };
    generate();
  }, [cloudCount]);

  const createCloud = () => {
    let div = document.createElement("div");
    div.className = "cloudBase";
    let x = Math.floor(Math.random() * window.innerWidth - 600);
    let y = Math.floor(Math.random() * window.innerHeight - 600);
    let z = 200 - Math.random() * 400;
    div.style.setProperty(
      "transform",
      `translateX(${x}px) translateY(${y}px) translateZ(${z}px)`
    );
    world.current.appendChild(div);

    for (var j = 0; j < count; j++) {
      var cloud = document.createElement("img");
      cloud.style.opacity = 0.8;
      const src = cloudImage;
      cloud.setAttribute("src", src);
      cloud.className = "cloudLayer";

      x = Math.floor(Math.random() * window.innerWidth - 600);
      y = Math.floor(Math.random() * window.innerHeight - 600);
      z = 200 - Math.random() * 400;
      let a = Math.random() * 360;
      let s = cloudCount / 5 + Math.random();
      x *= 0.3;
      y *= 0.3;
      cloud.data = {
        x: x,
        y: y,
        z: z,
        a: a,
        s: s,
        speed: 0.1 * Math.random(),
      };

      cloud.style.setProperty(
        "transform",
        `translateX(${x}px) translateY(${y}px) translateZ(${z}px) rotateZ(${a}deg) scale(${s})`
      );

      div.appendChild(cloud);
      layers.push(cloud);
    }
    return div;
  };

  window.addEventListener("mousewheel", onContainerMouseWheel);
  window.addEventListener("DOMMouseScroll", onContainerMouseWheel);

  function updateView() {
    document.documentElement.style.setProperty(
      "--world",
      `translateZ(${d}px) rotateX(${17}deg) rotateY(${-25}deg)`
    );
  }

  function onContainerMouseWheel(event) {
    event = event ? event : window.event;
    d = d - (event.detail ? event.detail * -5 : event.wheelDelta / 8);
    updateView();
  }

  function update() {
    for (var j = 0; j < layers.length; j++) {
      var layer = layers[j];
      layer.data.a += layer.data.speed;

      layer.style.setProperty(
        "transform",
        `translateX(${layer.data.x}px) translateY(${
          layer.data.y
        }px) translateZ(${
          layer.data.z
        }px) rotateX(${-worldXAngle}deg) rotateY(${-worldYAngle}deg) rotateZ(${
          layer.data.a
        }deg) scale(${layer.data.s})`
      );
      //layer.style.webkitFilter = 'blur(5px)';
    }
    requestAnimationFrame(update);
  }

  update();

  return (
    <div id="viewport" ref={viewport}>
      <div id="world" ref={world}></div>
    </div>
  );
};

export default Clouds;
