const countWordMatches = (obj, text) => {
  let countsAngst = 0;
  let countsEkel = 0;
  let countsFreude = 0;
  let countsKummer = 0;
  let countsWut = 0;
  const matchedWords = [];

  const splitWords = text.match(/[a-zA-ZäöüÄÖÜæÆ\\-]+/gi);

  for (let i = 0; i < splitWords.length; i++) {
    for (let j = 0; j < obj.angstArray.length; j++) {
      if (splitWords[i].includes(obj.angstArray[j])) {
        countsAngst++;
        matchedWords.push(splitWords[i]);
        break;
      }
    }
    for (let j = 0; j < obj.ekelArray.length; j++) {
      if (splitWords[i].includes(obj.ekelArray[j])) {
        countsEkel++;
        matchedWords.push(splitWords[i]);
        break;
      }
    }
    for (let j = 0; j < obj.kummerArray.length; j++) {
      if (splitWords[i].includes(obj.kummerArray[j])) {
        countsKummer++;
        matchedWords.push(splitWords[i]);
        break;
      }
    }
    for (let j = 0; j < obj.wutArray.length; j++) {
      if (splitWords[i].includes(obj.wutArray[j])) {
        countsWut++;
        matchedWords.push(splitWords[i]);
        break;
      }
    }
    for (let j = 0; j < obj.freudeArray.length; j++) {
      if (splitWords[i].includes(obj.freudeArray[j])) {
        countsFreude++;
        matchedWords.push(splitWords[i]);
        break;
      }
    }
  }

  // return the final count of matches and matched words
  return {
    Angst: countsAngst,
    Ekel: countsEkel,
    Freude: countsFreude,
    Kummer: countsKummer,
    Wut: countsWut,
    matchedWords: matchedWords,
  };
};

export default countWordMatches;
