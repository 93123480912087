import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AppStateProvider from "./appStateProvider/appStateProvider";
import { hot } from "react-hot-loader";
import Post from "../components/posts/post";
import postJSON from "../posts.json";
import Home from "../components/home/home";
import Circle from "../components/circle/circle";
import Clouds from "../components/clouds/clouds";

function App() {
  const [page, setPage] = useState("home");
  const [cloudCount, setCloudCount] = useState(0);
  const [fontVariation, setFontVariation] = useState(0);
  const [postMortality, setPostMortality] = useState(true);

  const handleButtonClick = () => {
    setTimeout(() => {
      setPage("post");
    }, 10);
  };

  const logo = (
    <Circle
      fontVariation={fontVariation}
      setFontVariation={setFontVariation}
      size={page === "home" ? "logo-size" : "logo-size--small"}
      state={page}
    />
  );

  const clouds = <Clouds cloudCount={cloudCount} />;

  return (
    <AppStateProvider>
      <Router>
        <Routes>
          <Route path="*" element={<h1>404 - Seite nicht gefunden</h1>} />
          <Route
            exact
            path="/"
            element={
              <Home
                setPostMortality={setPostMortality}
                clouds={clouds}
                setCloudCount={setCloudCount}
                logo={logo}
                handleButtonClick={handleButtonClick}
              />
            }
          ></Route>
          <Route
            path="/posts/:id"
            element={
              <Post
                postMortality={postMortality}
                setPostMortality={setPostMortality}
                clouds={clouds}
                logo={logo}
                page={page}
                setPage={setPage}
              />
            }
          ></Route>
          <Route
            path="/posts"
            element={
              <Navigate
                to={`/posts/${postJSON[0].year}-${postJSON[0].month}-${postJSON[0].day}`}
              />
            }
          />
        </Routes>
      </Router>
    </AppStateProvider>
  );
}

export default module.hot ? hot(module)(App) : App;
