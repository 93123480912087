const Branches = (p5) => {
  p5.state = {};
  p5.dispatch = () => {};

  const directions = ["0", "1", "2", "3", "4", "5", "6", "7"];
  const angleCount = 8;
  const strokeColor = "#02788e";

  let lines = [];
  let startBranches;
  let frequencyArray;
  let randomDirections;
  let startX;
  let startY;
  let angle;
  let headingBranchOne;
  let headingBranchTwo;
  let strokeLength;
  let strokeThickness;
  const emotions = [
    "angstFrequence",
    "ekelFrequence",
    "freudeFrequence",
    "kummerFrequence",
    "wutFrequence",
  ];

  let timeOut = (el) => {
    lines.push(
      new LineDrawer(
        startX,
        startY,
        randomDirections[el],
        strokeLength,
        strokeThickness
      )
    );
  };

  let isStopped = false;
  let isStarted = false;

  // -------------------------------------------------------------
  // Setup
  // -------------------------------------------------------------
  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, 800);
    p5.frameRate(10);
    p5.stroke(strokeColor);

    setStarterConfig();
    drawMainBranches();
  };

  // -------------------------------------------------------------
  // Draw
  // -------------------------------------------------------------
  p5.draw = () => {
    // Dynamic values
    startBranches = p5.state.startBranches;
    isStopped = p5.state.isStopped;

    // Clear canvas
    if (isStopped) {
      isStarted = p5.state.isStarted;
      clearCanvas();
      setStarterConfig();
    }

    if (!isStopped) {
      if (isStarted) {
        setStarterConfig();
        drawMainBranches();
      }
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const param =
          frequencyArray[Math.floor(Math.random() * frequencyArray.length)];
        line.step(param);
      }
    }
  };

  // -------------------------------------------------------------
  // Clear canvas and set start values
  // -------------------------------------------------------------
  const clearCanvas = () => {
    p5.clear();
  };

  const setStarterConfig = () => {
    lines = [];
    startBranches = p5.state.startBranches;
    randomDirections = p5.int(getMultipleRandom(directions, 5));
    startX = p5.int(p5.random(200, p5.width - 200));
    startY = 250;
    angle = 0;
    headingBranchOne = 0;
    strokeLength = 700;
    strokeThickness = 4;
    frequencyArray = [2];
    emotions.forEach((emotion) => {
      if (p5.state[emotion] > 0) {
        frequencyArray.push(p5.state[emotion]);
      }
    });
  };

  // -------------------------------------------------------------
  // Draw main branches
  // -------------------------------------------------------------
  const drawMainBranches = () => {
    for (let i = 0; i <= startBranches; i++) {
      setTimeout(function () {
        timeOut(i);
      }, i * 1500);
    }
    isStarted = false;
  };

  // -------------------------------------------------------------
  // LineDrawer
  // -------------------------------------------------------------
  class LineDrawer {
    constructor(x, y, heading, length, strokeThickness) {
      this.startX = x;
      this.startY = y;
      this.currentX = x;
      this.currentY = y;
      this.heading = heading;
      this.length = length;
      this.speed = 4;
      this.strokeThickness = strokeThickness;
    }

    step(frequence) {
      // Variable for current length
      const currentLength = p5.dist(
        this.startX,
        this.startY,
        this.currentX,
        this.currentY
      );

      if (currentLength >= this.length) {
        return;
      }

      // Prepare new coordinates for new lines
      // Save previous coordinates
      const prevX = this.currentX;
      const prevY = this.currentY;
      // Choose random angle
      angle = getRandomAngle(this.heading);
      // Create new coordinates
      this.currentX = this.currentX + p5.cos(p5.radians(angle)) * this.speed;
      this.currentY = this.currentY + p5.sin(p5.radians(angle)) * this.speed;

      // Draw new part of line
      p5.strokeWeight(this.strokeThickness);
      p5.line(prevX, prevY, this.currentX, this.currentY);

      // Create Branches on both sides
      // Create rundom number from 0 to 100
      let randomNumber = p5.int(p5.random(0, 100));

      // Some of the lines will take this direction
      if (randomNumber > 100 - frequence) {
        if (this.heading >= 0 && this.heading <= 6) {
          headingBranchOne = this.heading + 1;
        } else {
          headingBranchOne = this.heading - 1;
        }
        lines.push(
          new LineDrawer(
            this.currentX,
            this.currentY,
            headingBranchOne,
            this.length * 0.3,
            this.strokeThickness * 0.6
          )
        );
      }
      // Some of the lines will take this direction
      if (randomNumber < frequence) {
        if (this.heading >= 1 && this.heading <= 7) {
          headingBranchTwo = this.heading - 1;
        } else {
          headingBranchTwo = this.heading + 1;
        }
        lines.push(
          new LineDrawer(
            this.currentX,
            this.currentY,
            headingBranchTwo,
            this.length * 0.3,
            this.strokeThickness * 0.6
          )
        );
      }

      frequence -= 0.003;
      if (this.strokeThickness > 0.1) {
        this.strokeThickness -= 0.02;
      }
    }
  }

  // -------------------------------------------------------------
  // Get random angle function
  // -------------------------------------------------------------
  function getRandomAngle(currentDirection) {
    var a =
      ((p5.floor(p5.random(-angleCount, angleCount)) + 0.5) * 50) / angleCount;
    if (currentDirection === 0) return a - 90;
    if (currentDirection === 1) return a - 45;
    if (currentDirection === 2) return a;
    if (currentDirection === 3) return a + 45;
    if (currentDirection === 4) return a + 90;
    if (currentDirection === 5) return a + 135;
    if (currentDirection === 6) return a + 180;
    if (currentDirection === 7) return a + 225;
    return 0;
  }

  // -------------------------------------------------------------
  // Get random number out of array of numbers
  // -------------------------------------------------------------
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
};

export default Branches;
